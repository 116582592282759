// https: //github.com/tastejs/todomvc/blob/gh-pages/examples/react/js/todoItem.jsx

import React from 'react'; 
import styled from 'styled-components';
import tw from 'tailwind.macro';
import {Link} from 'gatsby'
import { isBrowser } from '../../utils/auth.service'
import { FetchItems } from '../../utils/list.service'

class ListItems extends React.Component {

  constructor(props) {
    super(props); 
    this.state = {
      items: null
    }
  }

  componentDidMount() {
    if (!isBrowser) { return; } 
    FetchItems().then(result => {
      this.setState({
        items: result
      });
    }).catch(err => {
      console.warn(err); 
    });
  }

  render() {
    return (
      <Wrapper>
        <Title>{this.props.title}</Title>

        {!this.state.items && ( 
          <p>No items found</p>
        )}

        { this.state.items && (
          <ul style={tw`list-none p-0`}>
            { this.state.items.map(item => (
              <li key={item.id}>
                <Link to={`/item/${item.slug}`} style={tw`text-black mb-2 block no-underline hover:underline`}>
                  <img src={item.user.picture} alt="avatar" height="20" style={{borderRadius: '50%', verticalAlign: 'middle'}} />
                  &nbsp;{item.title}
                </Link>
              </li>
            ))}
          </ul>
        )}

      </Wrapper>
    ); 
  }
}

export default ListItems; 

const Wrapper = styled.div`${tw``}`
const Title = styled.h3`${tw``}`
