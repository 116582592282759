import React from 'react'
import { Link } from 'gatsby'
import tw from 'tailwind.macro'
import styled from 'styled-components'
import Layout from '../components/shared/layout'
import SEO from '../components/shared/seo'
import { isAuthenticated } from '../utils/auth.service'
import { getUserInfo } from '../utils/user.service'
import ListItems from '../components/makeItem/MakeItemsList'
import Container from '../components/shared/Container'

class IndexPage extends React.Component {
  
  render() {
    
    const userInfo = getUserInfo();   
    console.log(userInfo)
    return (
      <Layout>
        <SEO title="Home" />
        <Container>
          { isAuthenticated && userInfo.slug && (
            <MyListLinkWrapper>
              <MyListLink to={`/list/${userInfo.slug}`}>Go to my list</MyListLink>
            </MyListLinkWrapper>
          )}

          { !isAuthenticated && (
            <LoginBox>
              <LoginLink to="/login">Login to continue</LoginLink>
            </LoginBox>
          )}

          <div style={tw`flex`}>
            <div style={tw`w-full md:w-1/2`}>
              <ListItems order="latest" count="10" title="Latest items created by the make101 community: " />
            </div>
            <div style={tw`w-full md:w-1/2`}>
              <h3>About this site</h3>
              <p>MAKE101 is a project by Urbanlink/Arn van der Pluijm. <br />
              The goal is to share as soon as possible with others what you would like to create. </p>
              <p>This stimulates, inspires and motivates you and others to actually start making stuff!</p>
            </div>
          </div>
        </Container>
      </Layout>
    )
  }
}

const MyListLinkWrapper = styled.div`${tw`text-center my-16`}`
const MyListLink = styled(Link)`${tw`text-3xl no-underline border-1 border-solid border-orange-500 p-4 text-orange-500 bg-white hover:bg-orange-100`}`
const LoginBox = styled.div`${tw`text-center p-32`}`
const LoginLink = styled(Link)`${tw`text-black text-xl`}`

export default IndexPage
